var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{staticClass:"vx-shadow",staticStyle:{"z-index":"20","position":"fixed"},attrs:{"absolute":"","temporary":"","disable-resize-watcher":""},on:{"input":_vm.closeOnOutsideClick},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-btn',{staticClass:"mt-1",attrs:{"retain-focus-on-click":false,"fab":"","small":"","icon":"","color":"accent","absolute":"","right":""}},[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("close")])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 flex-fill justify-center pa-5"},[_c('div',{staticClass:"d-flex flex-row flex-fill justify-center",staticStyle:{"align-items":"center"}},[_c('img',{staticClass:"rounded-full vx-shadow",staticStyle:{"width":"100px","height":"100px"},attrs:{"src":_vm.user && _vm.user.photo
            ? _vm.user.photo
            : "https://via.placeholder.com/50x50"}})]),(_vm.user)?[_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-fill justify-center mt-2"},[_c('span',{staticClass:"ft text-md black--text"},[_vm._v(_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lastName))])]),_c('div',{staticClass:"d-flex flex-row flex-grow-1 justify-center pt-1"},[_c('v-chip',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"ft font-weight-medium text-sm",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.user.phoneNumber)+" ")])],1)]:_vm._e()],2),_c('v-divider'),_c('div',{staticClass:"d-flex flex-column flex-grow-1",staticStyle:{"width":"100%","height":"100%"}},[_c('div',{staticClass:"link-container mt-2 d-flex flex-column flex-shrink-1 mx-3"},[_vm._l((_vm.links.slice(4)),function(menu,idx){return [(
            !menu.label &&
            _vm.user &&
            _vm.user.businessId &&
            _vm.user.businessId.isBusinessReseller === menu.isReseller
          )?_c('router-link',{key:idx,staticClass:"link-tag",attrs:{"tag":"a","to":{ name: menu.link }}},[_c('div',{staticClass:"inner-container"},[_c('i',{staticClass:"material-icons-outlined h-icon"},[_vm._v(_vm._s(menu.icon))]),_c('span',{staticClass:"ml-3 nav-links font-weight-medium text-sm",staticStyle:{"padding-top":"2px"}},[_vm._v(_vm._s(menu.text))]),(menu.isNew)?_c('v-chip',{staticClass:"ml-6 nav-links",staticStyle:{"position":"absolute","right":"12px","margin-top":"2px"},attrs:{"color":"primary","small":"","label":""}},[_c('span',{staticClass:"ft font-weight-medium text-uppercase",staticStyle:{"font-size":"11px"}},[_vm._v("Beta")])]):_vm._e()],1)]):(!menu.isReseller && !menu.label)?_c('router-link',{key:(idx + "-el"),staticClass:"link-tag",attrs:{"tag":"a","to":{ name: menu.link }}},[_c('div',{staticClass:"inner-container"},[[_c('i',{staticClass:"material-icons-outlined h-icon"},[_vm._v(_vm._s(menu.icon))]),_c('span',{staticClass:"ml-3 nav-links font-weight-medium text-sm",staticStyle:{"padding-top":"2px"}},[_vm._v(_vm._s(menu.text))]),(menu.isNew)?_c('v-chip',{staticClass:"ml-6 nav-links",staticStyle:{"position":"absolute","right":"12px"},attrs:{"color":"primary","small":"","label":""}},[_c('span',{staticClass:"ft font-weight-medium text-uppercase",staticStyle:{"font-size":"10px"}},[_vm._v("Beta")])]):_vm._e()]],2)]):(menu.label)?[_c('span',{key:idx,staticClass:"ft pl-1 animate__animated animate__fadeIn my-1 text-uppercase font-weight-medium pt-2 text-sm grey--text text--darken-1 nav-links"},[_vm._v(_vm._s(menu.label))])]:_vm._e()]})],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }